var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ControlNavbar'),(_vm.phoneObject)?_c('div',{staticClass:"container-fluid"},[_c('br'),_c('div',{staticClass:"row"},[(_vm.images.length > 0)?_c('div',{staticClass:"col-12 col-lg-6",staticStyle:{"direction":"initial"}},[(
            _vm.phoneObject.data && _vm.phoneObject.data.prices[0].images && _vm.images
          )?_c('a-carousel',{attrs:{"autoplay":true,"after-change":_vm.onCarouselChange}},_vm._l((_vm.images),function(image,index){return _c('div',{key:index},[_c('img',{staticStyle:{"max-height":"70vh"},attrs:{"src":image,"alt":index}})])}),0):_vm._e()],1):_vm._e(),_c('div',{class:("col-12 " + (_vm.images.length > 0 ? 'col-lg-6' : 'col-lg-12'))},[_c('div',{staticClass:"title-divider"},_vm._l((_vm.phoneName.split('\n')),function(title,index){return _c('h4',{key:index},[_vm._v(" "+_vm._s(title)+" ")])}),0),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.phoneObject.data.quantity.label}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'quantity',
                {
                  rules: [
                    {
                      required: true,
                    } ],
                  initialValue: _vm.phoneObject.data.quantity.default,
                } ]),expression:"[\n                'quantity',\n                {\n                  rules: [\n                    {\n                      required: true,\n                    },\n                  ],\n                  initialValue: phoneObject.data.quantity.default,\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"min":_vm.phoneObject.data.quantity.min,"max":_vm.phoneObject.data.quantity.max},on:{"change":_vm.qttChange}})],1),_c('a-form-item',{attrs:{"label":_vm.phoneObject.data.capacityLabel}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'capacity',
                {
                  rules: [
                    { required: true, message: _vm.locale.emptyFieldsWarning } ],
                  initialValue: _vm.phoneObject.data.prices[0].capacity,
                } ]),expression:"[\n                'capacity',\n                {\n                  rules: [\n                    { required: true, message: locale.emptyFieldsWarning },\n                  ],\n                  initialValue: phoneObject.data.prices[0].capacity,\n                },\n              ]"}],on:{"change":_vm.handleCapacityChange}},_vm._l((_vm.phoneObject.data.prices),function(variant,index){return _c('a-select-option',{key:index,attrs:{"value":variant.capacity}},[_vm._v(" "+_vm._s(variant.capacity)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.phoneObject.data.colorLabel}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'color',
                {
                  rules: [
                    { required: true, message: _vm.locale.emptyFieldsWarning } ],
                  initialValue: _vm.phoneObject.data.prices[0].color.name,
                } ]),expression:"[\n                'color',\n                {\n                  rules: [\n                    { required: true, message: locale.emptyFieldsWarning },\n                  ],\n                  initialValue: phoneObject.data.prices[0].color.name,\n                },\n              ]"}],on:{"change":_vm.handleColorChange}},_vm._l((_vm.phoneObject.data.prices),function(variant,index){return _c('a-select-option',{key:index,staticClass:"d-flex justify-content-between",attrs:{"value":variant.color.name}},[_c('span',[_vm._v(_vm._s(variant.color.name)+" ")]),_vm._v(" "),_c('span',{style:(("padding: 15px; background-color: " + (variant.color.hex) + "; margin-left: 2px;border-radius:45px; "))})])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.phoneObject.data.priceLabel}},[_c('p',[_vm._v(" "+_vm._s((_vm.price.price + _vm.price.payment) .toString() .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" "+_vm._s(_vm.$session.get("currency"))+" ")])]),_c('a-radio-group',{staticStyle:{"margin-bottom":"3%","margin-right":"9%"},on:{"change":_vm.onPaymentTypeChange},model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}},_vm._l((_vm.phoneObject.data.options),function(option,index){return _c('a-radio-button',{key:index,attrs:{"value":option.id}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1),_c('div',{staticClass:"middle"},[_c('a-form-item',{attrs:{"label":_vm.locale.address_1}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'address1',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.locale.emptyFieldsWarning,
                      } ],
                  } ]),expression:"[\n                  'address1',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: locale.emptyFieldsWarning,\n                      },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.locale.address_1}})],1),_c('a-form-item',{attrs:{"label":_vm.locale.address_2}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'address2',
                  {
                    rules: [
                      {
                        required: false,
                      } ],
                  } ]),expression:"[\n                  'address2',\n                  {\n                    rules: [\n                      {\n                        required: false,\n                      },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.locale.address_2}})],1),_c('a-form-item',{attrs:{"label":_vm.locale.full_name}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'fullName',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.locale.emptyFieldsWarning,
                      } ],
                  } ]),expression:"[\n                  'fullName',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: locale.emptyFieldsWarning,\n                      },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.locale.full_name}})],1),_c('a-form-item',{attrs:{"label":_vm.locale.phone_number}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone',
                  {
                    rules: [
                      {
                        required: true,
                        message: _vm.locale.emptyFieldsWarning,
                      } ],
                  } ]),expression:"[\n                  'phone',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: locale.emptyFieldsWarning,\n                      },\n                    ],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.locale.phone_number}})],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"danger","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.phoneObject.data.button)+" ")])],1)],1)],1)])]):_vm._e(),(_vm.selectedPrices)?_c('a-modal',{attrs:{"title":_vm.locale.paymentoptions,"footer":null},on:{"ok":function($event){_vm.paymentOptionsVisible = !_vm.paymentOptionsVisible}},model:{value:(_vm.paymentOptionsVisible),callback:function ($$v) {_vm.paymentOptionsVisible=$$v},expression:"paymentOptionsVisible"}},[(_vm.showPaymentOptions)?_c('div',_vm._l((_vm.$session.get('paymentOptions')),function(option){return _c('div',{key:option.id,on:{"click":function($event){return _vm.selectPaymentOption(option)}}},[(_vm.selectedPrices[("" + (option.id))])?_c('div',{staticClass:"\n            d-flex\n            justify-content-between\n            card_custom\n            pointer\n            align-items-center\n          "},[_c('p',[_vm._v(_vm._s(option.label))]),_c('p',[_vm._v(" "+_vm._s(_vm.selectedPrices[("" + (option.id))])+" ")])]):_vm._e()])}),0):_vm._e(),(_vm.paymentOption == 'creditcard')?_c('PaymentCC',{attrs:{"apiBody":_vm.apiBody},on:{"options":function ($event) {
          this$1.showPaymentOptions = $event;
        },"handleOldPhone":_vm.handleOldPhone}}):_vm._e()],1):_vm._e(),_c('loading',{attrs:{"loading":_vm.loader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }