<template>
  <div>
    <ControlNavbar/>

    <div class="container-fluid" v-if="phoneObject">
      <br/>
      <div class="row">
        <div
            class="col-12 col-lg-6"
            style="direction: initial"
            v-if="images.length > 0"
        >
          <a-carousel
              :autoplay="true"
              :after-change="onCarouselChange"
              v-if="
              phoneObject.data && phoneObject.data.prices[0].images && images
            "
          >
            <div v-for="(image, index) in images" :key="index">
              <img :src="image" :alt="index" style="max-height: 70vh"/>
            </div>
          </a-carousel>
        </div>
        <div :class="`col-12 ${images.length > 0 ? 'col-lg-6' : 'col-lg-12'}`">
          <div class="title-divider">
            <h4 v-for="(title, index) in phoneName.split('\n')" :key="index">
              {{ title }}
            </h4>
          </div>

          <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
              @submit="handleSubmit"
          >
            <a-form-item :label="phoneObject.data.quantity.label">
              <a-input-number
                  @change="qttChange"
                  style="width: 100%"
                  v-decorator="[
                  'quantity',
                  {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                    initialValue: phoneObject.data.quantity.default,
                  },
                ]"
                  :min="phoneObject.data.quantity.min"
                  :max="phoneObject.data.quantity.max"
              />
            </a-form-item>
            <a-form-item :label="phoneObject.data.capacityLabel">
              <a-select
                  v-decorator="[
                  'capacity',
                  {
                    rules: [
                      { required: true, message: locale.emptyFieldsWarning },
                    ],
                    initialValue: phoneObject.data.prices[0].capacity,
                  },
                ]"
                  @change="handleCapacityChange"
              >
                <a-select-option
                    v-for="(variant, index) in phoneObject.data.prices"
                    :key="index"
                    :value="variant.capacity"
                >
                  {{ variant.capacity }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="phoneObject.data.colorLabel">
              <a-select
                  v-decorator="[
                  'color',
                  {
                    rules: [
                      { required: true, message: locale.emptyFieldsWarning },
                    ],
                    initialValue: phoneObject.data.prices[0].color.name,
                  },
                ]"
                  @change="handleColorChange"
              >
                <a-select-option
                    v-for="(variant, index) in phoneObject.data.prices"
                    :key="index"
                    :value="variant.color.name"
                    class="d-flex justify-content-between"
                >
                  <span>{{ variant.color.name }} </span>&nbsp; &nbsp;
                  <span
                      :style="
                      `padding: 15px; background-color: ${variant.color.hex}; margin-left: 2px;border-radius:45px; `
                    "
                  ></span>
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="phoneObject.data.priceLabel">
              <p>
                {{
                  (price.price + price.payment)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ $session.get("currency") }}
              </p>
            </a-form-item>

            <a-radio-group
                v-model="paymentType"
                @change="onPaymentTypeChange"
                style="margin-bottom: 3%; margin-right: 9%"
            >
              <a-radio-button
                  v-for="(option, index) in phoneObject.data.options"
                  :key="index"
                  :value="option.id"
              >
                {{ option.label }}
              </a-radio-button>
            </a-radio-group>
            <div class="middle">
              <a-form-item :label="locale.address_1">
                <a-input
                    :placeholder="locale.address_1"
                    style="width: 100%"
                    v-decorator="[
                    'address1',
                    {
                      rules: [
                        {
                          required: true,
                          message: locale.emptyFieldsWarning,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>

              <a-form-item :label="locale.address_2">
                <a-input
                    :placeholder="locale.address_2"
                    style="width: 100%"
                    v-decorator="[
                    'address2',
                    {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>

              <a-form-item :label="locale.full_name">
                <a-input
                    :placeholder="locale.full_name"
                    style="width: 100%"
                    v-decorator="[
                    'fullName',
                    {
                      rules: [
                        {
                          required: true,
                          message: locale.emptyFieldsWarning,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item :label="locale.phone_number">
                <a-input
                    :placeholder="locale.phone_number"
                    style="width: 100%"
                    v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: true,
                          message: locale.emptyFieldsWarning,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>

            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
              <a-button type="danger" html-type="submit">
                {{ phoneObject.data.button }}
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
        v-if="selectedPrices"
    >
      <div v-if="showPaymentOptions">
        <div
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <div
              class="
              d-flex
              justify-content-between
              card_custom
              pointer
              align-items-center
            "
              v-if="selectedPrices[`${option.id}`]"
          >
            <p>{{ option.label }}</p>
            <p>
              {{ selectedPrices[`${option.id}`] }}
            </p>
          </div>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldPhone="handleOldPhone"
      />
    </a-modal>

    <loading :loading="loader"/>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import firebase from "firebase";
// import Slick from "vue-slick";
import ControlNavbar from "@/components/ControlNavbar";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Phone",
  components: {ControlNavbar, PaymentCC, Loading},
  data() {
    return {
      locale: null,
      loader: false,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "coordinated"}),
      price: {price: 0, payment: 0},
      phoneObject: null,
      images: null,
      color: null,
      capacity: null,
      paymentType: null,
      phoneName: null,

      // cc payment prices of selected phone
      selectedPrices: null,
      ccbodytoSubmit: null,
      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },

    setLoader(show) {
      this.loader = show;
    },
    // cc payment
    selectPaymentOption(payOption) {
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        this.form.validateFields((err, values) => {
          if (!err) {
            values = this.ccbodytoSubmit;
            values.type = this.phoneObject.type;
            values.fetchPrice = true;
            values.denominationId = this.phoneObject.id;
            values.numberOfItems = values.quantity;
            // delete values.Quantity;
            // this.checkPaymentMethod();
            // if (this.directRechargeField) {
            //   values.recipientMsisdn = this.directRechargeField;
            // }
            values.optionId = payOption.id;
            this.apiBody = values;
            this.apiBody.type = this.phoneObject.type;
            this.paymentOption = payOption.type;

            console.log("Received values of form: ", values);
          }
        });
      }
    },
    // checkPaymentMethod() {
    //   // the whole cc payment is here just check payment solution and the modal will do the rest

    //   // always check prices in voucher object and server.paymentOptions
    //   // if both are not null
    //   // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
    //   // else

    //   // get intersect between prices and server.paymentoptions (paymentoptions.id == voucher.prices['id']) id is the key of the json
    //   // if empty do normal api
    //   //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
    //   // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

    //   // if both or one of them null continue aade
    //   this.paymentOption = null;
    //   if (this.$session.get("paymentOptions") && this.voucher.data.prices) {
    //     if (
    //       this.$session.get("userToggleValue") == true &&
    //       this.$session.get("default_payment") == true
    //     ) {
    //       console.log("do normal api");
    //       // do normal api
    //       return true;
    //     } else {
    //       console.log("methods");
    //       // check payments option
    //       this.paymentOptionsVisible = true;
    //       return false;
    //     }
    //   } else {
    //     console.log("normal api");
    //     // do normal api
    //     return true;
    //   }
    // },

    // end cc payment
    onPaymentTypeChange(e) {
      console.log(`checked = ${e.target.value} ${this.paymentType}`);
      this.phoneObject.data.options.forEach((price) => {
        if (price.id == e.target.value) {
          console.log(`adding to price ${price.price}`);
          this.price.payment = price.price;
        }
      });
    },
    handleColorChange(e) {
      console.log(e, this.capacity);
      this.phoneObject.data.prices.forEach((variant) => {
        if (variant.color.name == e) {
          this.images = variant.images;
        }
      });
      this.handleCapacityChange();
    },
    handleCapacityChange(e) {
      console.log(e, this.color);
      let self = this;
      setTimeout(function () {
        self.form.validateFields((err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            let quantity = values.quantity;
            let capacity = values.capacity;
            let color = values.color;
            self.phoneObject.data.prices.forEach((variant) => {
              if (variant.color.name == color && variant.capacity == capacity) {
                self.price.price = variant.price * Number(quantity);
              }
            });
          }
        });
      }, 1000);
    },
    onCarouselChange() {
      // console.log(a, b, c);
    },
    qttChange(e) {
      console.log(e);
      let self = this;
      setTimeout(function () {
        self.form.validateFields((err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            let quantity = values.quantity;
            let capacity = values.capacity;
            let color = values.color;
            self.phoneObject.data.prices.forEach((variant) => {
              if (variant.color.name == color && variant.capacity == capacity) {
                self.price.price = variant.price * Number(quantity);
              }
            });
          }
        });
      }, 1000);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.paymentType) {
            // send error message
            this.$message.error("Please choose a payment type");
            return;
          } else {
            values.paymentOptionId = this.paymentType;
            values.numberOfItems = values.quantity;
            this.phoneObject.data.prices.forEach((variant) => {
              //get priceId
              if (
                  variant.color.name == values.color &&
                  variant.capacity == values.capacity
              ) {
                values.priceId = variant.id;
                this.selectedPrices = variant.prices;
              }
            }); // foreach
            // done foreach
            if (this.phoneName.length > 0) {
              console.log(this.phoneName);
              values.name = this.phoneName;
            } else {
              values.name = this.phoneName;
            }
            values.latitude = 10;
            values.longitude = 10;
            // values.price = this.price.price;
            console.log("payment type ", this.paymentType);
            if (this.paymentType == "creditcard") {
              console.log(values);
              this.ccbodytoSubmit = values;
              this.paymentOptionsVisible = true;
            } else {
              this.setLoader(true);
              this.getAxios().post(`${this.$session.get("baseURI")}sale/item/deliver`, values).then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "sale/item/deliver", "post")) {
                  // ok
                  console.log("after buyng phone", result);
                  this.success(result);
                }
              });
            }
            console.log("Received values of form: ", values);
          }
        }
      });
    },
    success(result) {
      this.$success({
        title: result.data.dialog.title,
        // JSX support
        content: () => (
            <div>
              <p>{result.data.dialog.message}</p>
            </div>
        ),
      });
    },
    handleSelectChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    handleOldPhone(values) {
      this.paymentOptionsVisible = false;
      this.setLoader(true);
      this.getAxios().post(`${this.$session.get("baseURI")}sale/item/deliver`, values).then((result) => {
        this.setLoader(false);
        if (this.checkApiRes(result, values, "sale/item/deliver", "post")) {
          // ok
          console.log("after buyng phone", result);
          this.success(result);
        }
      });
    },
  },
  created: function () {
    this.checkLocal();
    console.log(
        "phone: ",
        this.$route.params.phone || this.$session.get("backup")
    );

    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldPhone(values);
    }

    if (!this.$route.params.phone) {
      // undefined
      this.phoneObject = this.$session.get("backup");
    } else {
      this.phoneObject = this.$route.params.phone;
      this.$session.set("backup", this.$route.params.phone);
    }
    this.phoneName = this.phoneObject.data.name;
    if (this.phoneObject.data.name.length) {
      // do nothing
    } else {
      this.phoneObject.data.name = this.phoneObject.data.name.split("\n");
    }
    this.images = this.phoneObject.data.prices[0].images;
    this.price.price = this.phoneObject.data.prices[0].price;
  },
};
</script>

<style lang="scss">
// .slick-track {
//   margin-left: 0px !important;
//   margin-right: 0px !important;
// }
.ant-carousel .slick-dots li.slick-active button {
  background-color: red !important;
}

.ant-carousel .slick-dots li button {
  color: grey !important;
  background: grey !important;
}
</style>

<style lang="scss" scoped>
.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:hover {
  border-color: red !important;
  color: red !important;
  --antd-wave-shadow-color: red !important;
}

.title-divider {
  h4 {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ant-carousel > > > .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 red;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: red;
}

.ant-carousel > > > .slick-slide h3 {
  color: #fff;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
